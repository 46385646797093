<template>
  <div>
    <h4>Listado de personal</h4>
        <div class="text-right">
          <button v-on:click="regresar()" class="btn btn-secondary">
            Regresar
          </button>
          <button v-on:click="pdf()" class="btn btn-secondary">
            Descargar PDF
          </button>
          <br />&nbsp;
        </div>
        <ul>
          <tree-item
            class="item"
            :item="puestos"
            @make-folder="makeFolder"
            @add-item="addItem"
            @edit-files="editFiles"
            @edit-item="editItem"
            @del-item="delItem"
            @exp-item="expItem"
          ></tree-item>
        </ul>

    <b-modal ref="puesto" 
      v-bind:title="(puesto.id_puesto ? 'Editar' : 'Agregar') + ' puesto'"
      size="lg"
      centered
      hide-backdrop>
      <div class="row">
        <div class="form-group row col-md-12">
          <label class="col-md-5 col-form-label">Tipo dependencia</label>
          <div class="col-md-7">
            <div class="input-group">
              <select v-model="puesto.id_tipo" class="form-control">
                <option
                  v-for="tipo in tipos"
                  v-bind:value="tipo.id_tipo"
                  v-bind:key="tipo.id_tipo"
                >
                  {{ tipo.tipo }}
                </option>
              </select>
              <div class="input-group-append">
                <button
                  type="button"
                  class="btn btn-secondary"
                  v-on:click="agregaTipo"
                >
                  <font-awesome-icon icon="plus-square" />
                </button>
              </div>
            </div>
            <div class="errores" v-if="errores.id_tipo != ''">
              {{ errores.id_tipo }}
            </div>
          </div>
        </div>
        <div class="form-group row col-md-12">
          <label class="col-md-5 col-form-label">Nivel</label>
          <div class="col-md-7">
            <div class="input-group">
              <select v-model="puesto.id_nivel" class="form-control">
                <option
                  v-for="nivel in niveles"
                  v-bind:value="nivel.id_nivel"
                  v-bind:key="nivel.id_nivel"
                >
                  {{ nivel.nivel }}
                </option>
              </select>
              <div class="input-group-append">
                <button
                  type="button"
                  class="btn btn-secondary"
                  v-on:click="agregaNivel"
                >
                  <font-awesome-icon icon="plus-square" />
                </button>
              </div>
            </div>
            <div class="errores" v-if="errores.id_nivel != ''">
              {{ errores.id_nivel }}
            </div>
          </div>
        </div>
        <div class="form-group row col-md-12">
          <label class="col-md-5 col-form-label">Tipo personal</label>
          <div class="col-md-7">
            <div class="input-group">
              <select v-model="puesto.id_personal" class="form-control">
                <option
                  v-for="per in personal"
                  v-bind:value="per.id_personal"
                  v-bind:key="per.id_personal"
                >
                  {{ per.personal }}
                </option>
              </select>
              <div class="input-group-append">
                <button
                  type="button"
                  class="btn btn-secondary"
                  v-on:click="agregaPersonal"
                >
                  <font-awesome-icon icon="plus-square" />
                </button>
              </div>
            </div>
            <div class="errores" v-if="errores.id_personal != ''">
              {{ errores.id_personal }}
            </div>
          </div>
        </div>
        <div class="form-group row col-md-12">
          <label class="col-md-5 col-form-label">Funcion</label>
          <div class="col-md-7">
            <div class="input-group">
              <select v-model="puesto.funcion" class="form-control">
                <option
                  v-for="funcion in funciones"
                  v-bind:value="funcion"
                  v-bind:key="funcion"
                >
                  {{ funcion }}
                </option>
              </select>
              <div class="input-group-append">
                <button
                  type="button"
                  class="btn btn-secondary"
                  v-on:click="agregaFuncion"
                >
                  <font-awesome-icon icon="plus-square" />
                </button>
              </div>
            </div>

            <div class="errores" v-if="errores.id_personal != ''">
              {{ errores.id_personal }}
            </div>
          </div>
        </div>
        <div class="form-group row col-md-12">
          <label class="col-md-5 col-form-label">Puesto: </label>
          <div class="col-md-7">
            <input class="form-control" type="text" v-model="puesto.puesto" />
            <div class="errores" v-if="errores.puesto != ''">
              {{ errores.puesto }}
            </div>
          </div>
        </div>
        <div class="form-group row col-md-12">
          <label class="col-md-5 col-form-label">Grupo</label>
          <div class="col-md-7">
            <div class="input-group">
              <select v-model="puesto.id_grupo" class="form-control">
                <option
                  v-for="grupo in grupos"
                  v-bind:value="grupo.id_grupo"
                  v-bind:key="grupo.id_grupo"
                >
                  {{ grupo.grupo }}
                </option>
              </select>
              <div class="input-group-append">
                <button
                  type="button"
                  class="btn btn-secondary"
                  v-on:click="agregaGrupo"
                >
                  <font-awesome-icon icon="plus-square" />
                </button>
              </div>
            </div>

            <div class="errores" v-if="errores.id_personal != ''">
              {{ errores.id_personal }}
            </div>
          </div>
        </div>
        <div class="form-group row col-md-12" v-if="stipo == 8">
          <label class="col-md-5 col-form-label">Orden: </label>
          <div class="col-md-7">
            <input class="form-control" type="text" v-model="puesto.orden" />
            <div class="errores" v-if="errores.orden != ''">
              {{ errores.orden }}
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <h5>Personal</h5>
        </div>
        <div class="form-group row col-md-12">
          <label class="col-md-5 col-form-label">Apellido paterno: </label>
          <div class="col-md-7">
            <input class="form-control" type="text" v-model="puesto.actualp" />
            <div class="errores" v-if="errores.actualp != ''">
              {{ errores.actualp }}
            </div>
          </div>
        </div>
        <div class="form-group row col-md-12">
          <label class="col-md-5 col-form-label">Apellido materno: </label>
          <div class="col-md-7">
            <input class="form-control" type="text" v-model="puesto.actualm" />
            <div class="errores" v-if="errores.actualm != ''">
              {{ errores.actualm }}
            </div>
          </div>
        </div>
        <div class="form-group row col-md-12">
          <label class="col-md-5 col-form-label">Nombre: </label>
          <div class="col-md-7">
            <input class="form-control" type="text" v-model="puesto.actualn" />
            <div class="errores" v-if="errores.actualn != ''">
              {{ errores.actualn }}
            </div>
          </div>
        </div>
        <div class="form-group row col-md-12">
          <label class="col-md-5 col-form-label">Observaciones: </label>
          <div class="col-md-7">
            <textarea
              class="form-control"
              v-model="puesto.observaciones"
            ></textarea>
            <div class="errores" v-if="errores.observaciones != ''">
              {{ errores.observaciones }}
            </div>
          </div>
        </div>
        
        <div class="col-md-12" v-if="puesto.arbol == 1">
          <h5>Enlaces</h5>
        </div>
        <div class="form-group row col-md-12" v-if="puesto.arbol == 1">
          <label class="col-md-5 col-form-label"
            >Personal de enlace:<button
              v-on:click="agregaEnlace"
              class="btn btn-secondary"
            >
              <font-awesome-icon icon="plus" />
            </button>
          </label>
          <div class="col-md-7">
            <div v-for="(enlace, indice) in enlaces" v-bind:key="indice">
              <button
                v-on:click="eliminaEnlace(indice)"
                class="btn btn-secondary"
              >
                <font-awesome-icon icon="trash-alt" />
              </button>
              {{ enlace }}
            </div>
          </div>
        </div>
      </div>
      <br />
      <template #modal-footer="{ hide }">
        <button v-on:click="guardar" class="btn btn-primary">Guardar</button>
        <b-button  class="float-right" @click="hide()"> Cancelar </b-button>
      </template>
    </b-modal>
    
  </div>
</template>    
<script>
import Vue from "vue";
import TreeItem from "@/components/TreeItem.vue"
import api from "@/api";
// define the tree-item component

let campos_requeridos = [
  "id_tipo",
  "id_nivel",
  "id_personal",
  "puesto",
  "actualp",
  "actualm",
  "actualn",
];
export default {
  components: { TreeItem  },
  data() {
    return {
      stipo: 1,
      id: null,
      puesto: {},
      padre: {},
      puestos: {},
      tipos: [],
      grupos: [],
      personal: [],
      niveles: [],
      enlaces: [],
      errores: {},
      funciones: [
        "Titular",
        "Director",
        "Jefe de departamento",
        "Jefe de oficina",
        "Jefe de sección",
        "Analista",
        "Auxiliar administrativo",
      ],
      guardando: false,
    };
  },
  methods: {
    agregar: function () {
      this.puesto = {};
      this.$refs.puesto.show();
    },
    guardar: function () {
      if (this.validar()) {
        if (this.guardando) {
          return;
        }
        this.puesto.enlaces = this.enlaces.join(";");
        api.guardarPuesto(this.puesto)
          .then((response) => {
            let datos = response.data;
            if (datos != null && datos.respuesta) {
              this.puesto.id_puesto = datos.id;
              this.puesto.ruta = datos.ruta;
              if (!("children" in this.padre)) {
                this.padre.children = [];
              }
              this.padre.children.push(this.puesto);
              this.puesto = {};
              this.actualiza();
              alert(datos.mensaje);
              this.$refs.puesto.hide();
            } else {
              let msg = "";
              if (datos != null) {
                msg = datos.mensaje;
              }
              alert("Error al guardar la información: " + msg);
            }
            this.guardando = false;
          })
          .catch(() => {
            this.guardando = false;
          });
      } else {
        alert("Faltan datos por registrar o existen datos incorrectos");
      }
    },
    makeFolder: function (item) {
      Vue.set(item, "children", []);
      this.addItem(item);
    },
    addItem: function (item) {
      this.padre = item;
      this.puesto = {
        id_padre: item.id_puesto,
      };
      this.enlaces = [];
      this.$refs.puesto.show();
    },
    editFiles: function (item) {
      window.open(`organigrama/${item.id_puesto}`);
    },
    editItem: function (item) {
      this.puesto = item;
      if (this.puesto.enlaces == "") {
        this.enlaces = [];
      } else {
        this.enlaces = this.puesto.enlaces.split(";");
      }
      this.$refs.puesto.show();
    },
    expItem: function (item) {
      this.$router.push({ path: `/organigrama/${item.id_puesto}` })
    },
    regresar: function () {
      this.$router.push({ path: '/organigrama' })
    },
    delItem: function (item) {
      this.puesto = item;
      if (confirm("¿Confirma eliminar a " + item.puesto)) {
        api.eliminarPuesto(item.id_puesto).then((response) => {
            alert(response.data.mensaje);
            if (response.data.respuesta) {
              this.actualiza();
            }
          });
      }
    },
    pdf: function () {
      let url = "";
      if (this.id != null) {
        url = "?id=" + this.id;
      }
      window.open("pdf.php" + url);
    },
    agregaEnlace: function () {
      let enlace = prompt("Nombre del personal enlace que desea agregar");
      if (enlace != null && enlace != "") {
        this.enlaces.push(enlace);
      }
      console.log(this.enlaces);
    },
    eliminaEnlace: function (indice) {
      this.enlaces.splice(indice, 1);
    },
    agregaFuncion: function () {
      let nivel = prompt("Nombre de la función que desea agregar");
      if (nivel != null && nivel != "") {
        this.funciones.push(nivel);
        this.puesto.funcion = nivel;
      }
    },
    agregaNivel: function () {
      let nivel = prompt("Nombre del nivel que desea agregar");
      if (nivel != null && nivel != "") {
        this.$http
          .post("servicios/niveles.php", {
            nivel: nivel,
          })
          .then((response) => {
            this.actualizaNiveles();
            this.puesto.id_nivel = response.data.id;
          });
      }
    },
    agregaTipo: function () {
      let tipo = prompt("Nombre del tipo dependencia que desea agregar");
      if (tipo != null && tipo != "") {
        this.$http
          .post("servicios/tipos.php", {
            tipo: tipo,
          })
          .then((response) => {
            this.actualizaTipos();
            this.puesto.id_tipo = response.data.id;
          });
      }
    },
    agregaGrupo: function () {
      let grupo = prompt("Nombre del grupo que desea agregar");
      if (grupo != null && grupo != "") {
        api.guardarGrupo(grupo)
          .then((response) => {
            this.actualizaGrupos();
            this.puesto.id_grupo = response.data.id;
          });
      }
    },
    agregaPersonal: function () {
      let personal = prompt("Nombre del tipo de personal que desea agregar");
      if (personal != null && personal != "") {
        this.$http
          .post("servicios/personal.php", {
            personal: personal,
          })
          .then((response) => {
            this.actualizaPersonal();
            this.puesto.id_personal = response.data.id;
          });
      }
    },
    //Metodos de actualización
    actualiza: function () {
      let querys = "";
      var id = this.$route.params.id;
      if (id > 0) {
        this.id = id;
      } else {
        this.id = null;
      }
      if (this.id != null) {
        querys = "?id=" + this.id;
      }
      api.listarPuestos(querys).then((response) => {
          if (response.data) {
            this.puestos = response.data;
          }
        });
    },
    actualizaTipos: function () {
      api.listarTipos().then((response) => {
        if (response.data) {
          this.tipos = response.data;
        }
      });
    },
    actualizaGrupos: function () {
      api.listarGrupos().then((response) => {
        if (response.data) {
          this.grupos = response.data;
        }
      });
    },
    actualizaNiveles: function () {
      api.listarNiveles().then((response) => {
        if (response.data) {
          this.niveles = response.data;
        }
      });
    },
    actualizaPersonal: function () {
      api.listarPersonal().then((response)  =>{
        if (response.data) {
          this.personal = response.data;
        }
      });
    },
    validar: function () {
      this.errores = {};
      let registro = this.puesto;
      let errores = this.errores;

      let requeridos = Array.from(campos_requeridos);

      requeridos.forEach(function (requerido) {
        if (!(requerido in registro) || registro[requerido].length == 0) {
          errores[requerido] = "Requerido";
        }
      });
      return Object.keys(this.errores).length == 0;
    },
  },
  mounted: function () {
    this.actualizaTipos();
    this.actualizaNiveles();
    this.actualizaPersonal();
    this.actualizaGrupos();
    this.actualiza();
    this.$watch(
      () => this.$route.params,
      () => {        
        this.actualiza();
      }
    )
  },
};
</script>
<style>
li {
  list-style: none;
  margin-left: 0px;
}

ul {
  padding-inline-start: 20px;
}
</style>
