import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    usuario: null,
  },
  mutations: {
    asignarSesion(state, usuario) {
      localStorage.setItem('usuario', usuario);
      state.usuario = usuario.usuario
    },
    terminarSesion(state) {
      localStorage.setItem('usuario', null);
      state.usuario = null;
    },
    restaurarSesion(state) {
      if (state.usuario == null) {
        let temp = localStorage.getItem('usuario');
        if (temp != null) {
          try {
            state.usuario = JSON.parse(temp);
          } catch (e) { console.log(e); }
        }
      }
    },
  },
  actions: {
    iniciarSesion(context, datos) {
      return new Promise((resolve, reject) => {
        api.iniciarSesion(datos)
          .then((respuesta) => {
            let r = respuesta.data
            if (r.respuesta) {
              context.commit('asignarSesion', { usuario: r.datos });
              resolve(true);
            } else {
              resolve(false);
            }
          }).then(() => {
            reject(false);
          });

      });
    },
    restaurarSesion(context) {
      api.restaurarSesion().then((respuesta) => {
        let r = respuesta.data
        if (r.respuesta) {
          context.commit('asignarSesion', { usuario: r.datos });
        }
      });
    },
    terminarSesion(context) {
      context.commit('terminarSesion');
    },
  },
  getters: {
    sesionIniciada(state) {
      if (state.usuario != null && state.usuario.id_usuario != null) {
        return true;
      }
      return false;
    },
    esAdministrador(state, getters) {
      if (getters.sesionIniciada && state.usuario.id_tipousuario == 2) {
        return true;
      }
      return false;
    },
    usuario(state) {
      return state.usuario;
    },

  },
  modules: {
  }
})
