import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Inicio from '../views/Inicio.vue'
import Acceso from '../views/Acceso.vue'
import Salir from '../views/Salir.vue'
import Organigrama from '../views/Organigrama.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: Inicio
  },
  {
    path: '/organigrama',
    name: 'Organigrama',
    component: Organigrama
  },
  {
    path: '/organigrama/:id',
    name: 'Organigrama',
    component: Organigrama
  },  {
    path: '/acceso',
    name: 'Acceso',
    component: Acceso
  },
  {
    path: '/salir',
    name: 'Salir',
    component: Salir,
    beforeEnter(to, from, next) {
      if (store.getters.sesionIniciada) {
        next()
      } else {
        next('/acceso')
      }
    }

  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
