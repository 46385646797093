<template>
  <li>
      <div class="bg-light border-bottom p-1" :class="'nivel'+(item.arbol%5+1)">
          <div class="row">
              <span @click="toggle" style="margin-left: -10px; " v-if="isFolder"><font-awesome-icon v-show="!isOpen" icon="angle-right" /> <font-awesome-icon v-show="isOpen" icon="chevron-down" /> </span>

              <div class="col">
                  <a @click="toggle" href="javascript:;"><font-awesome-icon icon="briefcase" /> {{ item.nivel }} {{ item.puesto }}</a>
                  <div v-if="item.id_puesto > 0"><font-awesome-icon icon="user" /> {{item.funcion}} {{ item.actualp }} {{ item.actualm }} {{ item.actualn }}</div>
              </div>
              <div class="col-md-auto">
                  <!--<button class="btn btn-sm btn-info" @click="$emit('edit-files', item)" v-if="item.arbol==1"><font-awesome-icon icon="file-alt" /> </button>-->
                  <button class="btn btn-sm btn-info" @click="$emit('add-item', item)"><font-awesome-icon icon="plus-square" /> </button>
                  <button class="btn btn-sm btn-info" @click="$emit('edit-item', item)" v-if="item.id_puesto > 0" href="javascript:;"><font-awesome-icon icon="edit" /></button>
                  <button class="btn btn-sm btn-info" @click="$emit('del-item', item)" v-if="item.id_puesto > 0" href="javascript:;"><font-awesome-icon icon="trash-alt" /></button>
                  <button class="btn btn-sm btn-info" @click="$emit('exp-item', item)" v-if="item.id_puesto > 0" href="javascript:;"><font-awesome-icon icon="file-export" /></button>
              </div>
              <div class="col-12" v-if="item.arbol==1 && item.enlaces">
                  <b>Enlaces:</b>
                  <ol>
                      <li v-for="enlace in item.enlaces.split(';')" v-bind:key="enlace">{{enlace}}</li>
                  </ol>
              </div>
          </div>



      </div>
      <ul v-show="isOpen" v-if="isFolder">
      <tree-item
          class="item"
          v-for="(child, index) in item.children"
          :key="index"
          :item="child"
          @make-folder="$emit('make-folder', $event)"
          @edit-files="$emit('edit-files', $event)"
          @add-item="$emit('add-item', $event)"
          @edit-item="$emit('edit-item', $event)"
          @del-item="$emit('del-item', $event)"
          @exp-item="$emit('exp-item', $event)"
      ></tree-item>

      </ul>
  </li>    
</template>
<script>
export default {
  name: "TreeItem",
  //template: "#item-template",
  props: {
    item: Object,
  },
  data: function () {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle: function () {
      if (this.isFolder) {
        this.isOpen = !this.isOpen;
      } else {
        this.$emit("edit-item", this.item);
      }
    },
    editFiles: function () {
      this.$emit("edit-files", this.item);
    },
    delItem: function () {
      this.$emit("del-item", this.item);
    },
    editItem: function () {
      this.$emit("edit-item", this.item);
    },
    expItem: function () {
      this.$emit("exp-item", this.item);
    },
    makeFolder: function () {
      if (!this.isFolder) {
        this.$emit("make-folder", this.item);
        this.isOpen = true;
      }
    },
  },
  computed: {
    isFolder: function () {
      return this.item.children && this.item.children.length;
    },
  },
};
</script>