import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import Snackbar from 'vuejs-snackbar';
import axios from 'axios';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCropAlt, faTrash, faExclamationCircle, faFileUpload,
  faHome, faImage, faCamera, faVideo, faEdit, faFile, faPlusSquare,
  faFilePdf, faInfoCircle, faFileAlt, faChevronDown, faAngleRight,
  faArrowAltCircleLeft, faBriefcase, faTrashAlt, faUser, faFileExport, 
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import { faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faHome)
library.add(faFileExport)
library.add(faUser)
library.add(faPlus)
library.add(faTrashAlt)
library.add(faBriefcase)
library.add(faChevronDown)
library.add(faAngleRight)
library.add(faCamera);
library.add(faImage);
library.add(faCropAlt);
library.add(faFileUpload);
library.add(faVideo);
library.add(faEdit);
library.add(faTrash);
library.add(faFilePdf);
library.add(faFileAlt);
library.add(faPlusSquare);
library.add(faFontAwesome)
library.add(faFile);
library.add(faInfoCircle);
library.add(faExclamationCircle);
library.add(faArrowAltCircleLeft);

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('snackbar', Snackbar);

Vue.use(BootstrapVue, {
  theme: {
    "primary": "#FFCA28",
    "secondary": "#1976D2",
    "accent": "#82B1FF",
    "error": "#FF5252",
    "info": "#2196F3",
    "success": "#4CAF50",
    "warning": "#FB8C00"
  }
})
Vue.use(IconsPlugin)
Vue.use(Vuelidate)
axios.defaults.withCredentials = true

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
