import axios from 'axios'

export const API_URL = (location.hostname === "localhost" || location.hostname === "127.0.0.1") ? 'http://localhost/personal/api' : '/api';
export const PDF_URL = (location.hostname === "localhost" || location.hostname === "127.0.0.1") ? 'http://localhost/personal/formatos' : '/formatos';
export const ARCHIVOS_URL = (location.hostname === "localhost" || location.hostname === "127.0.0.1") ? 'http://localhost/personal/archivos' : '/archivos';
const api = {
    iniciarSesion( datos) {
        return axios.post(`${API_URL}/sesion.php`, datos);
    },
    restaurarSesion() {
        return axios.get(`${API_URL}/sesion.php`);
    },
    //////////////////////////////////////////////
    listarPuestos(querys) {
        return axios.get(`${API_URL}/puestos.php` + querys)
    },
    eliminarPuesto(id_puesto) {
        return axios.delete(`${API_URL}/puestos.php?id=${id_puesto}`);
    },
    guardarPuesto(puesto) {
        return axios.post(`${API_URL}/puestos.php`, puesto)
    },
    guardarGrupo(grupo) {
        return axios.post(`${API_URL}/grupos.php`, {grupo: grupo,});
    },
    listarPersonal() {
        return axios.get(`${API_URL}/personal.php`)
    },
    listarTipos() {
        return axios.get(`${API_URL}/tipos.php`);
    },
    listarGrupos() {
        return axios.get(`${API_URL}/grupos.php`);
    },
    listarNiveles() {
        return axios.get(`${API_URL}/niveles.php`)
    }
}
export default api;