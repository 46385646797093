<template>
  <div>
    <h4 class="modal-title">Acceso</h4>
    <p class="text-justify">Inicia sesión</p>
    <form autocomplete="off">
      <input
        autocomplete="false"
        type="hidden"
      />

      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="nombre">Usuario</label>
          <input
            maxlength="80"
            autocomplete="off"
            style="max-width: 500px"
            v-model.trim="$v.usuario.$model"
            :class="estatus($v.usuario)"
            class="form-control"
          />
          <div class="errores" v-if="!$v.usuario.required">Requerido</div>
        </div>
        <div class="form-group col-md-12">
          <label for="email">Contraseña</label>
          <input
            type="password"
            autocomplete="off"
            maxlength="80"
            style="max-width: 500px"
            v-model.trim="$v.contrasena.$model"
            :class="estatus($v.contrasena)"
            class="form-control"
          />
          <div class="errores" v-if="!$v.contrasena.required">Requerido</div>
        </div>
        <!--<div class="form-group col-md-12">
        <vue-recaptcha sitekey="6LcevOIaAAAAAIOQdj2WDRqBjwhrrgU9eeVO1B-V" ref="recaptcha" :loadRecaptchaScript="true" 
                @verify="onCaptchaVerified" @expired="onCaptchaExpired"></vue-recaptcha>       
      </div>-->
      </div>
    </form>
    <br />
    <div class="text-center">
      <b-button variant="primary"
        v-bind:disabled="guardando"
        v-on:click.stop.prevent="guardar"
      >
        Entrar
      </b-button>
    </div>
    <snackbar
      baseSize="5rem"
      ref="snackbar"
      :holdTime="5000"
      position="top-center"
    />
  </div>
</template>

<script>

import { required } from "vuelidate/lib/validators";
export default {
  name: "Acceso",
  components: {  },
  data() {
    return {
      usuario: "",
      contrasena: "",
      capcha: "",
      guardando: false,
    };
  },
  validations: {
    usuario: { required },
    contrasena: { required },
  },
  methods: {
    guardar() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("iniciarSesion", {
            usuario: this.usuario,
            password: this.contrasena,
          })
          .then((resp) => {
            if (resp) {
              this.$router.push("/");
            } else {
              this.$parent.error("Datos incorrectos");
            }
          })
          .catch(() => {
            this.$parent.error("Error inesperado");
          }) ;
      }
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.capcha = recaptchaToken;
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
